import { useEffect, useState } from 'react'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, message, Modal, Upload } from 'antd'
import { bool, func, oneOf, string } from 'prop-types'

import { useFiles } from '../../hooks'
import { getUrl } from '../../utils'

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})
}

export default function CustomUploader({
	variant,
	type,
	defaultFile,
	afterUpload,
	getFileAddressInsteadOfID,
	onlyPNG,
	onlyMP3,
	onlyMP4,
	className,
	// accept,
	...restProps
}) {
	const { uploadFile } = useFiles()

	const [previewModal, setPreviewModal] = useState({
		visible: false,
		image: ''
	})
	const [fileList, setFileList] = useState([])

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			// eslint-disable-next-line no-param-reassign
			file.preview = await getBase64(file.originFileObj)
		}

		setPreviewModal({
			image: file.url || file.preview,
			visible: true
		})
	}

	const handleRemove = () => {
		setFileList([])
		afterUpload?.(null)
		return true
	}

	const handleUpload = ({
		onSuccess,
		onError,
		onProgress: onUploadProgress,
		file
	}) => {
		uploadFile({
			file,
			onProgress: (percent) => {
				onUploadProgress({ percent })
			}
		})
			.then((filePath) => {
				if (filePath) {
					// if (getFileAddressInsteadOfID) {
					// 	getFileAddressByID(fileID).then((fileAddress) => {
					// 		afterUpload?.(fileAddress)
					// 		onSuccess()
					// 	})
					// } else {
					// 	afterUpload?.(fileID)
					// 	onSuccess()
					// }
					afterUpload?.(filePath)
					onSuccess()
				}
			})
			.catch((err) => {
				onError({ message: err })
				handleRemove()
			})
	}

	const beforeUpload = (file) => {
		const [, fileExtension] = file.name.split(/\.(?=[^.]+$)/)

		const isRestricted = [onlyPNG, onlyMP4, onlyMP3].some((el) => el === true)

		if (isRestricted) {
			const supported = []
			if (onlyPNG) supported.push('png', 'svg')
			if (onlyMP4) supported.push('mp4')
			if (onlyMP3) supported.push('mp3')

			if (!supported.includes(fileExtension)) {
				message.error(
					`Unsupported file format. only support: ${supported.join(', ')}`
				)
				return Upload.LIST_IGNORE
			}
		}
		return true
	}

	useEffect(() => {
		if (defaultFile) {
			const fileName = defaultFile.split('/').pop()

			const url = getUrl(defaultFile)
			setFileList([
				{
					uid: -1,
					name: fileName,
					status: 'done',
					url
				}
			])
		}
	}, [defaultFile])

	return (
		<div className={className}>
			<Upload
				accept="image/*"
				fileList={fileList}
				showUploadList
				onChange={({ fileList: fl, file: { status } }) => {
					if (status !== 'removed') setFileList(fl)
				}}
				listType="picture-card"
				maxCount={1}
				onPreview={variant === 'PictureWall' ? handlePreview : undefined}
				onRemove={handleRemove}
				customRequest={handleUpload}
				beforeUpload={beforeUpload}
				{...restProps}
			>
				{variant === 'PictureWall' && (
					<div className="flex flex-col">
						<PlusOutlined />
						<div className="mt-2">
							{fileList.length >= 1 ? 'Replace' : 'Upload'} File
						</div>
					</div>
				)}
				{variant === 'FileList' && (
					<Button
						icon={<UploadOutlined />}
						className="flex items-center justify-center"
					>
						{fileList.length >= 1 ? 'Replace' : 'Upload'} File
					</Button>
				)}
			</Upload>
			<Modal
				visible={previewModal.visible}
				footer={null}
				closable={false}
				centered
				onCancel={() =>
					setPreviewModal((prevState) => ({ ...prevState, visible: false }))
				}
			>
				<img alt="" className="w-full" src={previewModal.image} />
			</Modal>
		</div>
	)
}

CustomUploader.propTypes = {
	// accept: string,
	type: oneOf([
		'subject',
		'lesson',
		'level',
		'avatar',
		'question_type',
		'other'
	]).isRequired,
	afterUpload: func,
	getFileAddressInsteadOfID: bool,
	defaultFile: string,
	className: string,
	variant: oneOf(['PictureWall', 'FileList']),
	onlyPNG: bool,
	onlyMP3: bool,
	onlyMP4: bool
}

CustomUploader.defaultProps = {
	// accept: 'image/*',
	defaultFile: undefined,
	className: undefined,
	variant: 'PictureWall',
	afterUpload: undefined,
	getFileAddressInsteadOfID: false,
	onlyPNG: false,
	onlyMP3: false,
	onlyMP4: false
}
