import { UPLOADS_SERVER_URL } from './server-urls'

export function getPathsForDashboardScreen(value) {
	switch (value) {
		case 'Question Types': {
			return {
				viewPath: '/question-types'
			}
		}
		case 'Subjects': {
			return {
				viewPath: '/subjects',
				createPath: '/subjects?modal_is_open=true&mode=add'
			}
		}
		case 'Levels': {
			return {
				viewPath: '/levels',
				createPath: '/levels?modal_is_open=true&mode=add'
			}
		}
		case 'Lessons': {
			return {
				viewPath: '/lessons',
				createPath: '/lessons?modal_is_open=true&mode=add'
			}
		}
		case 'Micro Lessons': {
			return {
				viewPath: '/micro-lessons',
				createPath: '/micro-lessons?modal_is_open=true&mode=add'
			}
		}
		case 'Revisions': {
			return {
				viewPath: '/revision-lessons',
				createPath: '/revision-lessons?modal_is_open=true&mode=add'
			}
		}
		case 'Quizes': {
			return {
				viewPath: '/quiz-questions',
				createPath: '/quiz-questions?modal_is_open=true&mode=add'
			}
		}
		case 'Trophies': {
			return {
				viewPath: '/trophies'
			}
		}
		case 'Avatars': {
			return {
				viewPath: '/avatars',
				createPath: '/avatars?modal_is_open=true&mode=add'
			}
		}
		case 'Gifts': {
			return {
				viewPath: '/gifts',
				createPath: '/gifts?modal_is_open=true&mode=add'
			}
		}
		case 'Bug Reports': {
			return {
				viewPath: '/bug-reports'
			}
		}
		case 'Users': {
			return {
				viewPath: '/users'
			}
		}
		default: {
			throw new Error('Invalid Component')
		}
	}
}

/**
 * @param {string} path
 * @returns {string}
 * */
export const getUrl = (path) => {
	let source = path

	const hasHttpPrefix = source.startsWith('http')
	if (!hasHttpPrefix) {
		const alreadyFormatted = source.indexOf(UPLOADS_SERVER_URL) !== -1
		if (!alreadyFormatted) {
			source = `${UPLOADS_SERVER_URL}/${source.replace(/^\/uploads\//g, '/')}`
		}
	}

	return source
}
